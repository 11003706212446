const gzhname = '绿野星辰放心食材'
//weiapi请求返回值
const apiCode = window.apiCode

//广告位分类
const advertType = window.advertType
const wareStu = window.wareStu
//订单状态
const orderStatus = window.orderStatus

//热销分类
const hotSales = window.hotSales

const blackListOfTabbar = [
  '/ware/view',
  '/order/confirm',
  '/address/list',
  '/address/modify',
  '/ware/shoppingcart',
  '/mine/balance',
  '/mine/recharge',
]
const cookieKey = {
  sltoken: 'stoken',
}

//请求地址
const actionURL = window.actionURL2

export {
  gzhname,
  actionURL,
  apiCode,
  blackListOfTabbar,
  advertType,
  hotSales,
  orderStatus,
  cookieKey,
  wareStu,
}
