<template>
  <div>
    <div class="banner_mid">
      <van-search
        shape="round"
        show-action
        v-model="searchVaule"
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white" :show-indicators="true">
        <van-swipe-item v-for="item in bannerlist" :key="item.Id" @click="myUrlTo(item.H5Link)">
          <img class="bannerimg" :src="item.ImgPath" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <MidNav v-if="false"></MidNav>
    <MemberActivities v-if="false"></MemberActivities>
    <div class="hotspace">
      <h2>好物热推</h2>
    </div>
    <GoodsList></GoodsList>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { setCookie } from '@/unit/common'
import { onMounted, onBeforeUnmount, ref } from 'vue'

import { webApi, apiCode } from '@/api'
import { advertType } from '@/config/constInfo'
import MidNav from '@/components/MidNav'
import MemberActivities from '@/components/MemberActivities'
import GoodsList from '@/components/GoodsList'
import { jumUrl } from '@/unit/common'

export default {
  name: 'indexView',
  components: { MidNav, MemberActivities, GoodsList },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const show = ref(false)
    const searchVaule = ref('')
    const bannerlist = ref([])
    const categorylist = ref([])
    const testclick = async () => {
      show.value = true
    }
    const myUrlTo = (urlpath) => {
      jumUrl(urlpath, router)
    }

    const onSearch = () => {
      if (searchVaule.value) {
        router.push({ path: '/ware/search', query: { title: searchVaule.value } })
      }
    }
    const getBannerList = async () => {
      var banneridx = 1
      var bannerNode = advertType.find((x) => x.title == 'Banner轮播')
      if (bannerNode) {
        banneridx = bannerNode.index
      }
      const apidata = await webApi.AdvertLink.getList(banneridx)
      if (apidata.code == apiCode.success) {
        bannerlist.value = apidata.data
      }
    }

    onMounted(async () => {
      await getBannerList()
      //本地登陆跳过测试 上线要注释（后端一定要注释）
      let stk = route.query.stk
      if (stk && stk == 'test') {
        setCookie('stoken', 'start_test')
      }
      //本地登陆跳过测试 上线要注释
    })
    onBeforeUnmount(() => {})

    return {
      searchVaule,
      myUrlTo,
      onSearch,
      show,
      testclick,
      bannerlist,
      categorylist,
    }
  },
}
</script>

<style scoped lang="less">
.my-swipe .van-swipe-item {
  width: 7.5rem;
  height: 4rem;
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
  .bannerimg {
    width: 7.5rem;
    height: 4rem;
  }
}
.hotspace {
  width: 7.5rem;
  height: 0.9rem;
  box-sizing: border-box;
  padding: 0 0.2rem;
  h2 {
    height: 0.9rem;
    line-height: 0.9rem;
    font-size: 0.34rem;
    color: #333;
  }
}

//
.fl {
  float: left;
}
.fr {
  float: right;
}
.bestcategory {
  width: 7.1rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 0.2rem;
  ul {
    overflow: hidden;
  }
  li {
    float: left;
    width: 20%;
    height: 1.2rem;
    h2 {
      text-align: center;
      font-size: 0.28rem;
      font-weight: 600;
      height: 0.4rem;
      margin-top: 0.25rem;
      line-height: 0.4rem;
      color: #333;
    }
    span {
      display: block;
      width: 1rem;
      font-size: 0.14rem;
      color: #ccc;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      border-radius: 0.7rem;
      margin: 0 auto;
    }
  }
  .active {
    h2 {
      color: #39a9ed;
    }
    span {
      color: #fff;
      background-color: #39a9ed;
    }
  }
}
.datalist {
  width: 7.1rem;
  min-height: 7.1rem;
  margin: 0.1rem auto;
  overflow: hidden;
  .render {
    width: 3.5rem;
    min-height: 3.45rem;
  }
}
.item {
  width: 3.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.2rem;
  background-color: #fff;
  .photo {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    box-sizing: border-box;
    padding: 0.1rem;
  }
  .info {
    box-sizing: border-box;
    padding: 0.1rem 0.2rem;
    h3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 0.28rem;
      line-height: 0.35rem;
      max-height: 0.7rem;
      margin-bottom: 0.05rem;
    }
    .summary {
      display: block;
      line-height: 0.3rem;
      max-height: 0.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgb(232, 34, 14);
      margin-bottom: 0.05rem;
    }
    .price {
      height: 0.4rem;
      line-height: 0.4rem;
      font-size: 0.34rem;
      color: rgb(232, 34, 14);
      .icon {
        font-weight: 600;
        font-size: 0.24rem;
      }
    }
  }

  .tools {
    padding: 0rem 0.2rem 0.2rem;
    overflow: hidden;
    .gwc {
      width: 0.5rem;
      height: 0.5rem;
      float: right;
      border-radius: 0.26rem;
      background-color: red;
    }
  }
}

.addmore {
  width: 7.5rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.28rem;
  text-align: center;
  color: #666;
}
</style>
