import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import Api from './api'
import VantLoad from './config/vantLoad'
import store from './store'

const app = createApp(App)
app.use(store)
app.use(Api)
app.use(router)
app.use(VantLoad)

router.beforeEach((to) => {
  window.document.title = to.meta.title == undefined ? '--' : to.meta.title
  if (to.meta.requiresAuth && !store.state.loginstatus) {
    return {
      path: '/login',
      query: { redirect: to.fullPath },
    }
  }
  store.commit('pushCurrentPath', to.path)
})
app.mount('#app')
