import { actionURL, apiCode } from '@/config/constInfo'
import { webApi } from '@/api'

function getWebUrl(item) {
  const path = actionURL.webhost + item
  return path
}

//@click跳转方法，主机名相同则走router-push
function jumUrl(input, router) {
  if (input.startsWith('http://') || input.startsWith('https://') || input.startsWith('//')) {
    if (input.indexOf(actionURL.localhostname) > 0) {
      const localpath = input.split(actionURL.localhostname)[1]
      router.push(localpath)
    } else {
      window.location.href = input
    }
  } else {
    router.push(input)
  }
}

function getDateStr(input) {
  return input.substr(0, 10)
}

function setCookie(name, value, days = 1) {
  var Days = days
  var exp = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString()
}
//读取cookies
function getCookie(name) {
  var arr,
    reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')

  if ((arr = document.cookie.match(reg))) return unescape(arr[2])
  else return null
}

//删除cookies
function delCookie(name) {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1)
  var cval = getCookie(name)
  if (cval != null) document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString()
}

//从url字符串提取key的值
function getValFromUrlStr(input, key) {
  var reg = new RegExp(`[?|&]${key}=([^&]+)`)
  var mc = input.match(reg)
  if (mc) {
    return mc[1]
  } else {
    return ''
  }
}
function FixedContentImg(input) {
  const pt = /(<img\b[^<>]*?\bsrc[\s\t\r\n]*=[\s\t\r\n]*[""'])/g
  return String(input).replace(pt, '$1//' + actionURL.webhostnopro)
}

function bMapToQQMap(lng, lat) {
  if (lng == null || lng == '' || lat == null || lat == '' || lat == 0 || lat == 0)
    return [lng, lat]

  var x_pi = 3.14159265358979324
  var x = parseFloat(lng) - 0.0065
  var y = parseFloat(lat) - 0.006
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
  var lngq = (z * Math.cos(theta)).toFixed(7)
  var latq = (z * Math.sin(theta)).toFixed(7)

  return [lngq, latq]
}

function debounce(fn = Function, delay = 600) {
  let time = null
  return (params) => {
    if (time) {
      clearTimeout(time)
    }

    time = setTimeout(() => {
      fn(params)
      time = null
    }, delay)
  }
}

function antiShake(fn = Function, time = 600) {
  let o_time = null
  return (params) => {
    if (o_time) return
    o_time = setTimeout(() => {
      fn(params)
      o_time = null
    }, time)
  }
}

//转换分享链接，没有shareid时添加，有分享id时替换
function tansShareLink(href, key, shareid) {
  var reg = new RegExp(`[?|&]${key}=([^&]+)`)
  var mc = href.match(reg)
  if (mc) {
    let newstr = `&${key}=${shareid}`
    if (mc[0].startsWith('?')) {
      newstr = `?${key}=${shareid}`
    }
    return href.replace(reg, newstr)
  } else {
    return `${href}&shareid=${shareid}`
  }
}

async function InitWxConfig(jsapilist = ['openLocation']) {
  const apidata = await webApi.Wxuser.WxJSAPIConf(window.location.href)
  if (apidata.code == apiCode.success) {
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: apidata.data.appid, // 必填，公众号的唯一标识
      timestamp: apidata.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: apidata.data.nonceStr, // 必填，生成签名的随机串
      signature: apidata.data.signature, // 必填，签名
      jsApiList: jsapilist, // 必填，需要使用的JS接口列表
    })
  }
}

export {
  debounce,
  antiShake,
  getWebUrl,
  getDateStr,
  jumUrl,
  setCookie,
  getCookie,
  delCookie,
  getValFromUrlStr,
  tansShareLink,
  FixedContentImg,
  bMapToQQMap,
  InitWxConfig,
}
