<template>
  <div class="bestcategory">
    <ul>
      <li
        v-for="item in renderMenu"
        :key="item.index"
        :class="{ active: item.index == currentMenu }"
        @click="renderGoodsList(item.index)"
      >
        <h2>{{ item.title }}</h2>
        <span>{{ item.subtitle }}</span>
      </li>
    </ul>
  </div>
  <div class="datalist">
    <div class="render fl">
      <div class="item" v-for="item in hotSalesLeftRender" :key="item.Id">
        <router-link :to="'/ware/view?id=' + item.Wid">
          <img class="photo" :src="item.ImgFront_s" />
          <div class="info">
            <h3>{{ item.Title }}</h3>
            <span class="summary">{{ item.Summary }}</span>
            <div class="pricearea">
              <span class="icon">&yen;</span>
              <span class="price">{{ item.SellingPrice }}</span>
            </div>
          </div>
        </router-link>
        <div class="tools">
          <div class="gwc" @click="addtoshopcart(item.Wid)">
            <van-icon name="shopping-cart-o" />
          </div>
        </div>
      </div>
    </div>
    <div class="render fr">
      <div class="item" v-for="item in hotSalesRightRender" :key="item.Id">
        <router-link :to="'/ware/view?id=' + item.Wid">
          <img class="photo" :src="item.ImgFront_s" />
          <div class="info">
            <h3>{{ item.Title }}</h3>
            <span class="summary">{{ item.Summary }}</span>
            <div class="pricearea">
              <span class="icon">&yen;</span>
              <span class="price">{{ item.SellingPrice }}</span>
            </div>
          </div>
        </router-link>
        <div class="tools">
          <div class="gwc" @click="addtoshopcart(item.Wid)">
            <van-icon name="shopping-cart-o" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <van-action-sheet v-model:show="popupshow" title="&nbsp;" :closeable="false">
    <div class="popupcontent">
      <div class="popupgoodsinfo clearfloat">
        <img :src="selectedItem?.Thumbnail" class="goodsphoto" />
        <div class="renderinfo">
          <div class="pricearea">
            <div class="price">
              <span class="icon">&yen;</span>
              <span class="amount">{{ selectedItem?.SellingPrice }}</span>
            </div>
            <div class="retailprice">
              <span class="icon">&yen;</span>
              <span class="amount">{{ selectedItem?.RetailPrice }}</span>
            </div>
            <div class="goodschooseinfo">
              <span>已选</span>
              <span class="goodsname">{{ selectedItem?.ProductName }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="skuarea clearfloat">
        <h2>规格</h2>
        <ul>
          <li
            v-for="item in currentProductSNodeList"
            :key="item.Id"
            @click="pickUpGoods(item)"
            :class="{ active: item.Id == selectedItem.Id }"
          >
            {{ item.ProductName }}
          </li>
        </ul>
      </div>
    </div>

    <div class="btn_tools" v-if="currentProduct?.GAttr == 1">
      <div class="btn_addcart fullbtn" @click="addtocartup(selectedItem)">加入购物车</div>
    </div>
  </van-action-sheet>

  <div ref="el_addmore" class="addmore">加载中...</div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { hotSales } from '@/config/constInfo'
import { webApi, apiCode } from '@/api'
import { Toast } from 'vant'
export default {
  name: 'GoodsList',
  setup() {
    const router = useRouter()
    const renderMenu = ref([])
    const currentMenu = ref('1')
    const el_addmore = ref(null)
    const hotSalesColl = ref([])
    const hotSalesLeftRender = ref([])
    const hotSalesRightRender = ref([])
    const currentProduct = ref({})
    const currentProductSNodeList = ref([])
    const selectedItem = ref({})
    const popupshow = ref(false)

    const store = useStore()
    const getHotsales = async () => {
      const apidata = await webApi.Ware.getHotSalesList()
      if (apidata.code == apiCode.success) {
        hotSalesColl.value = apidata.data
        renderGoodsList(currentMenu.value)
      }
    }
    const pickUpGoods = (item) => {
      selectedItem.value = item
    }
    const addtoshopcart = async (wid) => {
      const apidata = await webApi.Ware.getWareInfo(wid)
      if (apidata.code == apiCode.success) {
        currentProduct.value = apidata.data
        currentProductSNodeList.value = apidata.specification
        console.log('currentProduct', currentProduct.value)
        if (currentProduct.value.GAttr == 1) {
          selectedItem.value = currentProductSNodeList.value[0]
          if (currentProductSNodeList.value.length == 1) {
            console.log('只有一个规格')
            //只有一个规格，直接加入购物车
            await addtocartup(currentProductSNodeList.value[0])
          } else {
            popupshow.value = true
            console.log('多个规格')
            //弹出层选择规格
          }
        } else {
          //票务类型，直接进入详情页
          router.push({ path: '/ware/view', query: { id: currentProduct.value.Id } })
        }
      } else {
        currentProduct.value = null
        currentProductSNodeList.value = []
        Toast('产品异常')
      }
    }

    const addtocartup = async (selectedItemNode) => {
      const postdata = {
        skuid: selectedItemNode.Id,
        counts: 1,
      }
      const apidata = await webApi.Ware.addshoppingcart(postdata)
      if (apidata.code == apiCode.success) {
        let cartnode = store.state.shoppingCartList.find((x) => x.SKUID == postdata.skuid)
        if (!cartnode) {
          const newcartinfo = {
            Counts: postdata.counts,
            Id: apidata.data,
            SKUID: postdata.skuid,
            SKUTitle: selectedItemNode.ProductName,
            SellingPrice: selectedItemNode.SellingPrice,
            Thumbnail: selectedItemNode.Thumbnail,
            WareId: currentProduct.value.Id,
            WareName: currentProduct.value.Title,
            checked: 0,
          }
          store.commit('pushShoppingCartList', newcartinfo)
        }
        Toast('加入购物车完成')
        popupshow.value = false
      } else {
        Toast(apidata.message)
      }
    }

    const renderGoodsList = (position) => {
      currentMenu.value = position
      let tempdata = hotSalesColl.value.filter((x) => x.Flag == currentMenu.value)
      hotSalesLeftRender.value = tempdata
        .sort((a, b) => {
          return a.Sort - b.Sort
        })
        .filter((item, index) => {
          return index % 2 == 0
        })

      hotSalesRightRender.value = tempdata
        .sort((a, b) => {
          return a.Sort - b.Sort
        })
        .filter((item, index) => {
          return index % 2 != 0
        })
      console.log('hotSalesLeftRender', hotSalesLeftRender.value)
    }
    onMounted(async () => {
      //目前只取前5个于首页展示
      renderMenu.value = hotSales.slice(0, 5)
      currentMenu.value = hotSales[0].index
      await getHotsales()
      // const obs = new IntersectionObserver((entries) => {
      //   if (entries[0].isIntersecting) {
      //     getdata()
      //   }
      // })
      // obs.observe(el_addmore.value)
    })
    return {
      renderMenu,
      currentMenu,
      renderGoodsList,
      hotSalesLeftRender,
      hotSalesRightRender,
      el_addmore,
      addtoshopcart,
      popupshow,
      selectedItem,
      currentProduct,
      currentProductSNodeList,
      pickUpGoods,
      addtocartup,
    }
  },
}
</script>

<style lang="less" scoped>
.fl {
  float: left;
}
.fr {
  float: right;
}
.bestcategory {
  width: 7.1rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 0 0.2rem;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 0.2rem;
  ul {
    overflow: hidden;
  }
  li {
    float: left;
    width: 20%;
    height: 1.2rem;
    h2 {
      text-align: center;
      font-size: 0.3rem;
      font-weight: 600;
      height: 0.4rem;
      margin-top: 0.25rem;
      line-height: 0.4rem;
      color: #333;
    }
    span {
      display: block;
      width: 1rem;
      font-size: 0.18rem;
      color: #ccc;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      border-radius: 0.7rem;
      margin: 0 auto;
    }
  }
  .active {
    h2 {
      color: #39a9ed;
    }
    span {
      color: #fff;
      background-color: #39a9ed;
    }
  }
}
.datalist {
  width: 7.1rem;
  margin: 0.1rem auto;
  padding-bottom: 1rem;
  overflow: hidden;
  .render {
    width: 3.5rem;
  }
}
.item {
  width: 3.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.2rem;
  background-color: #fff;
  .photo {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    box-sizing: border-box;
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .info {
    box-sizing: border-box;
    padding: 0.1rem 0.2rem;
    h3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 0.28rem;
      line-height: 0.35rem;
      max-height: 0.7rem;
      margin-bottom: 0.05rem;
    }
    .summary {
      display: block;
      line-height: 0.3rem;
      max-height: 0.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: rgb(232, 34, 14);
      margin-bottom: 0.05rem;
    }
    .pricearea {
      height: 0.4rem;
      line-height: 0.4rem;
      font-size: 0.34rem;
      color: rgb(232, 34, 14);
      .icon {
        font-weight: 600;
        font-size: 0.24rem;
      }
    }
  }

  .tools {
    padding: 0rem 0.2rem 0.2rem;
    overflow: hidden;
    .gwc {
      width: 0.5rem;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      color: #fff;
      font-size: 0.34rem;
      font-weight: 600;
      float: right;
      border-radius: 0.26rem;
      background-color: red;
    }
  }
}

.btn_tools {
  text-align: center;
  font-size: 0.3rem;
  color: #fff;
  width: 7.5rem;
  height: 1rem;
  padding: 0.05rem 0.3rem;
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  .normalbtn {
    width: 3.3rem;
    line-height: 0.8rem;
    height: 0.8rem;
    border-radius: 0.4rem;
  }
  .fullbtn {
    width: 100%;
    line-height: 0.8rem;
    height: 0.8rem;
    border-radius: 0.4rem;
  }
  .btn_addcart {
    float: left;
    background-color: #f2140c;
  }
  .btn_buying {
    float: right;
    background-color: #ffba0d;
  }
}
.popupcontent {
  width: 7.5rem;
  // height: 17rem;
  padding: 0 0.3rem 2rem;
  box-sizing: border-box;
  .popupgoodsinfo {
    width: 6.9rem;
    //height: 2rem;
    .goodsphoto {
      display: block;
      float: left;
      width: 2rem;
      height: 2rem;
    }
    .renderinfo {
      float: right;
      width: 4.75rem;
      //height: 2rem;

      .price {
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.46rem;
        font-weight: 600;
        color: rgb(232, 34, 14);
        .icon {
          font-size: 0.24rem;
        }
      }
      .retailprice {
        text-decoration-line: line-through;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.28rem;
        color: #999;
        .icon {
          font-size: 0.24rem;
        }
      }
      .goodschooseinfo {
        font-size: 0.24rem;
        color: #999;
        .goodsname {
          color: #333;
          margin-left: 0.2rem;
        }
      }
    }
  }
  .skuarea {
    margin-top: 0.3rem;
    width: 6.9rem;
    min-height: 2rem;
    h2 {
      font-size: 0.28rem;
      height: 0.4rem;
      line-height: 0.4rem;
    }
    ul {
      margin-top: 0.2rem;
      li {
        height: 0.6rem;
        font-size: 0.24rem;
        line-height: 0.6rem;
        float: left;
        padding: 0 0.3rem;
        background-color: #f2f2f2;
        margin: 0 0 0.2rem 0.2rem;
        border-radius: 0.32rem;
        border: 1px solid #f2f2f2;
      }
      .active {
        background: #fcedeb;
        border: 1px solid #f2270c;
        color: #f2270c;
      }
    }
  }
  .skucount {
    margin-top: 0.3rem;
    width: 6.9rem;
    min-height: 1rem;
    h2 {
      float: left;
      font-size: 0.28rem;
      height: 0.4rem;
      line-height: 0.4rem;
    }
    .countoper {
      float: right;
    }
  }
}
.addmore {
  display: none;
  width: 7.5rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.28rem;
  text-align: center;
  color: #666;
}
</style>
