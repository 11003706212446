import { createStore } from 'vuex'

export default createStore({
  state: {
    //用户是否登陆
    loginstatus: false,
    //用户信息
    userinfo: {},
    currentpath: '',
    addressInfo: {},
    addressList: [],
    shoppingCartList: [],
    categoryList: [],
    mycoupons: [],
  },
  getters: {},
  mutations: {
    loginsuccess(state, payload) {
      state.loginstatus = true
      state.userinfo = payload
    },
    loginFailed(state) {
      state.loginstatus = false
      state.userinfo = {}
    },
    pushCurrentPath(state, payload) {
      state.currentpath = payload
    },
    pushAddressInfo(state, payload) {
      state.addressInfo = payload
    },
    pushAddressList(state, payload) {
      state.addressList = state.addressList.concat(payload)
    },
    pushCategoryData(state, payload) {
      state.categoryList = payload
    },
    pushMycoupons(state, payload) {
      state.mycoupons = state.mycoupons.concat(payload)
    },
    rushMycoupons(state, payload) {
      state.mycoupons = payload
    },
    updateAddressInList(state, payload) {
      let temp = state.addressList.find((x) => x.id == payload.id)
      for (const key in temp) {
        if (temp.hasOwnProperty.call(temp, key)) {
          temp[key] = payload[key]
        }
      }
    },
    delFromAddressList(state, payload) {
      state.addressList = state.addressList.filter((x) => x.id != payload)
      if (state.addressInfo.id == payload) {
        state.addressInfo = {}
      }
    },
    pushShoppingCartList(state, payload) {
      state.shoppingCartList = state.shoppingCartList.concat(payload)
    },

    delFromShoppingCartList(state, payload) {
      state.shoppingCartList = state.shoppingCartList.filter((x) => x.Id != payload)
    },
  },
  actions: {},
  modules: {},
})
